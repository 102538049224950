<template>
  <div>
    <div class="d-flex align-center justify-center" style="height: 100vh;">
      <v-progress-circular :size="80" color="primary" indeterminate width="6"></v-progress-circular>
    </div>
  </div>
</template>
<script>
export default {
  created () {
    window.opener.postMessage({ ...(this.$route.query || {}), authFrom: 'GOOGLE' }, '*')
    self.close()
  }
}
</script>
